@charset "UTF-8";

@font-face {
  font-family: "icons";
  src:url("fonts/icons.eot");
  src:url("fonts/icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/icons.woff") format("woff"),
    url("fonts/icons.ttf") format("truetype"),
    url("fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\58";
}
.icon-alert-triangle:before {
  content: "\61";
}
.icon-apple-icon:before {
  content: "\62";
}
.icon-arrow-down-icon:before {
  content: "\63";
}
.icon-arrow-left-icon:before {
  content: "\64";
}
.icon-arrow-right-icon:before {
  content: "\65";
}
.icon-arrow-thin:before {
  content: "\66";
}
.icon-arrow-up-icon:before {
  content: "\67";
}
.icon-avatar-icon:before {
  content: "\68";
}
.icon-calendar:before {
  content: "\69";
}
.icon-cancel-button-icon:before {
  content: "\6a";
}
.icon-cog-icon:before {
  content: "\6b";
}
.icon-compass-icon:before {
  content: "\6c";
}
.icon-controls-icon:before {
  content: "\6d";
}
.icon-delta-t-icon:before {
  content: "\6e";
}
.icon-direction-icon:before {
  content: "\6f";
}
.icon-down-arrow-icon:before {
  content: "\70";
}
.icon-download:before {
  content: "\71";
}
.icon-evapotranspiration-icon:before {
  content: "\72";
}
.icon-extreme-weather-icon:before {
  content: "\73";
}
.icon-favourite-full-icon:before {
  content: "\74";
}
.icon-favourite-icon:before {
  content: "\75";
}
.icon-filter-icon:before {
  content: "\76";
}
.icon-freezing-icon:before {
  content: "\77";
}
.icon-full-screen-icon:before {
  content: "\78";
}
.icon-google-play-icon:before {
  content: "\79";
}
.icon-hamburger-icon:before {
  content: "\7a";
}
.icon-hamburger-step:before {
  content: "\41";
}
.icon-humidity-icon:before {
  content: "\42";
}
.icon-info-icon:before {
  content: "\43";
}
.icon-left-arrow-icon:before {
  content: "\44";
}
.icon-list-icon:before {
  content: "\45";
}
.icon-map-icon:before {
  content: "\46";
}
.icon-map-layers:before {
  content: "\47";
}
.icon-new-window-icon:before {
  content: "\48";
}
.icon-notification:before {
  content: "\49";
}
.icon-notification-icon:before {
  content: "\4a";
}
.icon-pause-icon:before {
  content: "\4b";
}
.icon-pestfax-map-icon:before {
  content: "\4c";
}
.icon-play-icon:before {
  content: "\4d";
}
.icon-play-icon-1:before {
  content: "\4e";
}
.icon-plus-icon:before {
  content: "\4f";
}
.icon-quote-icon:before {
  content: "\50";
}
.icon-radar:before {
  content: "\51";
}
.icon-radar-icon:before {
  content: "\52";
}
.icon-rainfall-icon:before {
  content: "\53";
}
.icon-refresh-icon:before {
  content: "\54";
}
.icon-right-arrow-icon:before {
  content: "\55";
}
.icon-science-api-icon:before {
  content: "\56";
}
.icon-search-icon:before {
  content: "\57";
}
.icon-send-icon:before {
  content: "\59";
}
.icon-soil-moisture-icon:before {
  content: "\5a";
}
.icon-soild-api-icon:before {
  content: "\30";
}
.icon-solar-icon:before {
  content: "\31";
}
.icon-speech-bubble-icon:before {
  content: "\32";
}
.icon-station-alert-icon:before {
  content: "\33";
}
.icon-stations:before {
  content: "\34";
}
.icon-sub-nav-horizontal-icon:before {
  content: "\35";
}
.icon-sub-nav-vertical-icon:before {
  content: "\36";
}
.icon-sun-icon:before {
  content: "\37";
}
.icon-telephone-icon:before {
  content: "\38";
}
.icon-temperature-icon:before {
  content: "\39";
}
.icon-tick-icon:before {
  content: "\21";
}
.icon-upload:before {
  content: "\22";
}
.icon-wa-map:before {
  content: "\23";
}
.icon-weather-icon:before {
  content: "\24";
}
.icon-weather-variables:before {
  content: "\25";
}
.icon-wind-arrow:before {
  content: "\26";
}
.icon-wind-icon:before {
  content: "\27";
}
