.airtemperatureminlast24hrs,
.station-marker.airtemperatureminlast24hrs,
#map-legend.airtemperatureminlast24hrs div
{
  &.ml-g20 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l20 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l10 {
    background-color: #D6289E;
    color: $White;
  }
  &.ml-l5 {
    background-color: #F885CB;
  }
  &.ml-l4 {
    background-color: #FB8D28;
  }
  &.ml-l3 {
    background-color: #FAB94D;
  }
  &.ml-l2 {
    background-color: #76BC6C;
  }
  &.ml-l1 {
    background-color: #B0DFA6;
  }
  &.ml-l0 {
    background-color: #D5E5FA;
  }
  &.ml-ln1 {
    background-color: #B1CFFC;
  }
  &.ml-ln2 {
    background-color: #82AEF1;
  }
  &.ml-ln3 {
    background-color: #4F93FA;
  }
  &.ml-ln4 {
    background-color: #2873C0;
  }
  &.ml-ln5 {
    background-color: #00395B;
    color: $White;
  }
  &.ml-na {
    background-color:#ccc ;
    border: solid 1px #ccc;
  }

}

#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs
    {
        &.ml-ln5, &.ml-ln4 , &.ml-ln3 , &.ml-ln2 , &.ml-ln1, &.ml-l0, &.ml-l1 , &.ml-l2, &.ml-l3, &.ml-l4, &.ml-l5, &.ml-l10, &.ml-l20 ,&.ml-g20 , &.ml-na  {
         display: none;
       }
    }
  }
  &.ml-g20 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-g20
    {
      display: flex;
    }
  }
  &.ml-l20 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l20
    {
      display: flex;
    }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l5 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l5
    {
      display: flex;
    }
  }
  &.ml-l4 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l4
    {
      display: flex;
    }
  }
  &.ml-l3 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l3
    {
      display: flex;
    }
  }
  &.ml-l2 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l2
    {
      display: flex;
    }
  }
  &.ml-l1 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l1
    {
      display: flex;
    }
  }
  &.ml-l0 {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-l0
      {
        display: flex;
      }
  }
  &.ml-ln1 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-ln1
    {
      display: flex;
    }
}
  &.ml-ln2 {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-ln2
      {
        display: flex;
      }
  }
  &.ml-ln3 {
    div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-ln3
    {
      display: flex;
    }
  }
  &.ml-ln4 {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-ln4
      {
        display: flex;
      }
  }
  &.ml-ln5 {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-ln5
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.airtemperatureminlast24hrs.ml-na
      {
        display: flex;
      }
  } 
}