#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.rainfallmetricslast7days
    {
        &.ml-l5 , &.ml-l10 , &.ml-l15 , &.ml-l25, &.ml-l50 , &.ml-l100 , &.ml-l150 , &.ml-g150 , &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l25 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l100 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l100
      {
        display: flex;
      }
  }
  &.ml-l150 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-l150
      {
        display: flex;
      }
  }
  &.ml-g150 {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-g400
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.rainfallmetricslast7days.ml-na
      {
        display: flex;
      }
  }
}




.rainfallmetricslast7days,
.station-marker.rainfallmetricslast7days,
#map-legend.rainfallmetricslast7days div {

  &.ml-g150 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l150 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l100 {
    background-color: #FB8D28;
    color: $White;
  }
  &.ml-l50 {
    background-color: #FAB94D;
  }
  &.ml-l25 {
    background-color: #95CF8B;
}
  &.ml-l15 {
    background-color:#82AEF1;

  }
  &.ml-l10 {
    background-color: #B1CFFC;

  }
  &.ml-l5 {
    background-color: #D5E5FA;
  }
  &.ml-na {
    background-color: #ccc;
    &.ml-lowavailability {
      background-color: #a3a3a3;
      border-color: #a3a3a3;
    }
  }

}



