@charset "utf-8";

$defaultBlue: #15263a;
$darkBlueBtn: #0c1a2b;
$lightBlueBtnBorder: #a4c5f2;
$primary-nav-border: #183558;
$lightGreyBorder: #e2e2e2;
$input-border: #dee2e6;
$greyIconColor: #848484;
$buttonOn: #006dff;
$buttonOff: #094596;
$red: #e20000;
$black: #000;
$orange: #f6650e;
$green: #30c80c;
$text: #111111;
$textGrey: #cbcbcb;
$bgGrey: #f2f2f2;
$white: #fff;
$yellow: #ffb601;
$lightBlueGraph: #caeaff;
$favorite: #ffb601;
$dbca: #a3e089;
$wcorp: #18cff0;


$rd-button-bg: #003e8a;

$primary: $defaultBlue;
$primaryDark: $darkBlueBtn;

//Dark and Light Shared styles
$White: #fff;
$Black: #000;
$BluePrimary: #15263a;
$Green: #30c80c;
$Orange: #FF7F00;
$Red: #E60000;

//New Light Theme styles

$HeadingText: #414146;
$LightText: #414146;
$ParagraphText: #414146;
$Value: #000;
$BlueHighlight: #028AFF;
$LightGreyBG: #F4F4F7;
$GreyButton: #E4E6E8;
$BodyBG: #E4E6E8;

//New Dark Theme styles
$BodyBGDark: #191B22;
$ContentBodyDark: #23252C;
$BorderDark: #2A2B31;
$HeadingTextDark: #B9B8B8;
$ValueTxtDark: $White;

//Dark theme

body.dark-gotham {
  background: $BodyBGDark;



  #map-content .radar-time,   .menuBarItemContainer .inner-wrapper .multiselect .radar-outage-info  {
    background-color: $ContentBodyDark !important;
  }

   #date-picker-wrapper {
    background-color: $ContentBodyDark!important;
    input {
      background-color:$BodyBGDark!important;
    }
  }



  .search-container.active .search-content .letter-wrapper div.listing .cta-wrappper .cta.disabled {
    background-color: transparent !important;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.3) !important;
    cursor: not-allowed;
  }


  div.tabular-data-container  #date-picker-wrapper input {
    background: $ContentBodyDark!important;
    border: solid 1px #2A2B31!important;
  }

  .ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: #007ad9!important;
  }



  .menuBar .menuBarItem.menuBarSettingsItem app-more-select .menuBarItemContainer .inner-wrapper button {
    color: #B9B8B8 !important;
  }


  .menuBar .menuBarItem button.btn.btn-outline-secondary {
    border-right: solid 1px #2A2B31!important;
  }

  @media (max-width: 800px) {
    .menuBar .menuBarItem button.btn.btn-outline-secondary {
      border-right: solid 1px transparent!important;
    }
  }


  .search-container.active.scrolling .search-wrapper input {
    background-size: 30px!important;
  }

  .search-container {
    .search-wrapper {
      .outer-filter-wrapper .filter-options {
        border-bottom: solid 1px $BorderDark!important;
      }
        input {
        background-color : #333540!important;
      }
    }
    &.active {
      .search-wrapper .close-button {
        color: #fff;
      }
      .search-wrapper .outer-filter-wrapper .filter-options button {
        background: #181a21!important;
        border: solid 1px $BorderDark!important;
        &.active {
          background: #006dff!important;
          border: solid 1px #006dff!important;
        }
      }
      .search-wrapper input {
        background: #193557 url("/assets/images/search-icon-new.svg") 10px center no-repeat!important;
        background-size: 40px!important;
        color: #fff!important;
      }
      .search-wrapper button.cancel {
        background: $buttonOn!important;
      }
      .search-content .letter-wrapper div.listing .code {
        border: solid 1px $BorderDark!important;
      }
      .search-content .letter-wrapper div.listing {
        color: rgba(255, 255, 255, 0.7)!important;

      }
      .search-content .letter-wrapper .search-group-letter {
        color: #fff!important;
      }
    }
  }


  .search-container.active.scrolling .search-wrapper .outer-filter-wrapper .filter-options {
    border-bottom: none!important;
  }




  .search-container.active .search-wrapper {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.6)!important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.6)!important;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255, 255, 255, 0.6)!important;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255, 255, 255, 0.6)!important;
    }
  }



  .search-container.active {
    .search-container-content {
      background-color : #191B22!important;
      .search-wrapper {
        background-color : #191B22!important;
        input {
          background-color : #191B22!important;
        }
      }
      .search-content {
        background-color : #191B22!important;
      }
    }
  }




  .search-container.active .search-content .letter-wrapper div.listing .cta-wrappper .cta {
    background: $buttonOn!important;
    color: rgba(255, 255, 255, 0.7)!important;
    &:hover {
      color: rgba(255, 255, 255, 1)!important;
    }
  }





  .menuBar .menuBarItem button,.menuBarItemContainer .inner-wrapper .multiselect label, .menuBarItemContainer .inner-wrapper .singleselect label, .toggle-button-wrapper {
    background: #181a21!important;
    border: solid 1px transparent!important;
    border-radius: 4px;
    color: #B9B8B8;
    &.active {
      color: #fff;
    }
    &.hover {
      span.notification {
        background: $buttonOn;
        color: #fff;
      }
    }
  }


  .radar-time .btn-wrapper, #date-picker-wrapper  .btn-wrapper {
    background: $ContentBodyDark url("/assets/images/move-white.svg") center center no-repeat!important ;
    background-size: 18px!important;
    img {
      display: none;
    }
  }





  .menuBar .menuBarItem button.active, .menuBarItemContainer .inner-wrapper .multiselect label.active, .menuBarItemContainer .inner-wrapper .singleselect label.active {
    background-color: $buttonOn!important;
    border-color: transparent!important;
    color: #fff;
  }


  .metric-arrow-down {
    border-bottom: 15px solid $ContentBodyDark!important;
  }


  .menuBarItemContainer .inner-wrapper .multiselect .station-group-wrapper .group {
    background: #848484!important;
    color: rgba(255, 255, 255, 0.8)!important;
    &.active {
      background: $ContentBodyDark!important;
    }
  }





  .menuBar .menuBarItemContainer {
    background: $ContentBodyDark!important;
  }

  .menuBar .menuBarItem:first-child .menuBarItemContainer, .actions {
    background: $ContentBodyDark!important;
    button {
      border-right:none!important;
    }
  }




  .selected-item {
    background: $black;
  }


  #main-container .settingsContainer .selectWrapper angular2-multiselect .cuppa-dropdown .selected-list .c-btn {
    border-color: rgba(255, 255, 255, 0.3)!important;
  }




 .ui-table .ui-table-tbody > tr > td {
    border: solid 1px #2A2B31!important;
  }




  #main-container .settingsContainer .selectWrapper {
    background: transparent;
  }



  .tabular-data-container .paginator .ui-paginator-icon {
    width: 20px;
  }


  #summary-metric-container {
    background: $ContentBodyDark;

  }

  .ui-table .ui-table-thead > tr > th {
    color: $white;
    font-weight: 500;
  }

  .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon, .form-check-label {
    color: $white!important;
  }

  .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
    color: $white;
  }


  .ui-table .ui-sortable-column .ui-sortable-column-icon, .ui-table .ui-sortable-column .ui-sortable-column-icon:hover {
    color: $white;
  }


  #date-picker-wrapper .icon.icon-calendar {
    color: $white!important;
  }


  div.tabular-data-container .ui-table-caption.ui-widget-header .toolbar-wrapper .toolbar-wrapper-inside div.divider {
    background: rgba(255, 255, 255, 0.1);
  }

  .selectWrapper angular2-multiselect .cuppa-dropdown .selected-list .c-btn, .selectWrapper .toolbar-wrapper .toolbar-wrapper-inside .ui-helper-wrapper .ui-helper-clearfix button, .ui-table .ui-table-thead > tr > th {
    border-color: $BorderDark!important;
  }


  .selectWrapper .toolbar-wrapper .toolbar-wrapper-inside .ui-helper-wrapper .ui-helper-clearfix button {
    background: $ContentBodyDark!important;
  }




  .ui-table .ui-table-tbody > tr {
    background-color: transparent;
    color: #f9f6f6;
  }

  .ui-chkbox .ui-chkbox-box {
    border: solid 1px rgba(2, 138, 255, 0.8);
    background-color: #020202;
  }

  .ui-inputtext {
    background-color: $ContentBodyDark;
    color: $white;
  }

  .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
    color: $white;
  }

  button.btn-long.view-dashboard:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: $BodyBGDark !important;
    color: #a2a2a2;
    border-top: solid 1px #d0d0d0;
  }

  .cuppa-dropdown .selected-list .c-btn {
    border: solid 1px rgba(255, 255, 255, 0.3);
  }
  .cuppa-dropdown .selected-list .c-btn, .cuppa-dropdown .dropdown-list .list-area label {
    color: #f2f2f2;
  }
  .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
    fill: #f2f2f2;
  }

  .list-area {
    background: $BodyBGDark;
  }

  .arrow-down, .arrow-up, .cuppa-dropdown .dropdown-list .arrow-2 {
    border-bottom: solid 15px $BodyBGDark;
  }

  .cuppa-dropdown .dropdown-list ul li:hover {
    background: $black;
    color: #f2f2f2;
  }

  .outer-wrapper .container.top .content-page a:hover {
    color: $white;
    border-bottom: dotted 1px $white;
  }

  #dashboard-content .DashboardComponentWrapper .Layout.DuelContainer .Feedback .ComponentBoxContainer p a:hover {
    color: $white !important;
    border-bottom: dotted 1px $white !important;
  }

  .loadingPlaceHolder {
    background: $BodyBGDark;
    color: $white;
  }


  #main-container #countdown #countdown-controls #countdownRefreshBtn:hover {
    background: $buttonOn;
  }

  #main-container #countdown #countdown-controls #countdownPausePlayBtn:hover {
    background: $buttonOn;
  }

  #main-container #mapInfoPanel .station-variables-wrapper ul.nav-pills li a.nav-link.disabled {
    color: rgba(162, 162, 162, 0.5);
  }

  .content-page p.introDesc {
    border-top: solid 1px rgba(255, 255, 255, 0.3) !important;
  }

  #sliderPausePlayBtn {
    color: $white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }

  #leftMenu {
    background-color: $ContentBodyDark;
    color: $HeadingTextDark;
    .profile-wrapper {
      background: $BodyBGDark !important;
    }
    ul li {
      color: $HeadingTextDark !important;
      cursor: pointer !important;
      &.version-number {
        &:hover {
          color: $White !important;
        }
        span {
          &:hover {
            color: $White !important;
          }
        }
      }
      &:hover {
        background: $BlueHighlight !important;
        color: $White !important;
      }
      &.version-number {
        background: transparent !important;
        box-shadow: none !important;
        cursor: pointer !important;
      }
      a {
        color: $HeadingTextDark !important;
        cursor: pointer !important;
        &:hover {
          background: $BlueHighlight !important;
          color: $White !important;
        }
      }
    }
  }

  .leaflet-retina .fullscreen-icon, .fullscreen-icon {
    background-image: url("/assets/images/full-screen-map-icon.png");
    background-size: 16px 16px;
  }



  @media (max-width: 991px) {
    #main-container #mapInfoPanel .station-variables-wrapper ul.nav-pills li a.nav-link.disabled {
      color: #6d6d6d;
    }

    .corporate-header-wrapper .SubNav .d-inline-block.show.dropdown .dropdown-menu.show a.feedback {
      color: #b9b8b8 !important;
    }

  }

  @media (max-width: 991px) {
    div.tabular-data-container.active .ui-table-caption.ui-widget-header .toolbar-wrapper .toolbar-wrapper-inside {
      border: solid 1px $BorderDark;
    }
  }

  #main-container #mapInfoPanel .station-variables-wrapper ul.nav-pills li:last-child {
    border-right: none;
  }

  #main-container {
    color: $HeadingTextDark;
    background: $BodyBGDark;

    #filter-btn:hover {
      box-shadow: none;
      border: solid 1px rgba(255, 255, 255, 0.5);
    }

    .station-header.scrolling {
      -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 1);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 1);
    }



    .filter-options button.btn.drop-down-button {
      margin-top: 1px;
      background: $ContentBodyDark;
      border: solid 1px #2A2B31;
    }

    .corporate-header-wrapper, .map-filter,
    #dashboard-content .DashboardComponentWrapper .Layout.SeeAlso .ComponentBoxContainer .ComponentBoxContainerBody .ToolWrapper a .IconWrapper,
    div.tabular-data-container.active, div.tabular-data-container .ui-table.ui-widget, div.tabular-data-container .tabular-data-buttons button.btn-primary, .secondary-header, .outer-wrapper, .station-table .all-stations-wrapper .toolbar-wrapper, #mapInfoPanel .header-bg,
    #mapInfoPanel .station-variables-wrapper .tab-content, #dashboard-content, .station-header, #dashboard-content .DashboardComponentWrapper .Layout.Soil .ComponentBoxContainer,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerBody .DateContainer,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerBody .RainfallSummaryWrapper,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerBody .km, .tabular-data-inside-container,
    .ui-table-caption.ui-widget-header, ui-paginator.ui-widget-header, .ui-paginator .ui-paginator-last:hover, .ui-paginator .ui-paginator-prev:hover, .ui-paginator .ui-paginator-first:hover,
    .ui-multiselect .ui-multiselect-panel, .ui-widget-content, div.tabular-data-container.active .ui-table-caption.ui-widget-header .toolbar-wrapper .toolbar-wrapper-inside, .station-table .station-table-inside, .ui-table .ui-table-thead > tr > th, .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover, .selectWrapper {
      background: $BodyBGDark;
    }
    #header, .outer-wrapper .container.top .content-page, .table-wrapper, .station-table .table td, .station-table .table th,
    #mapInfoPanel .station-variables-wrapper ul.nav-pills li a.nav-link, #mapInfoPanel .station-variables-wrapper ul.nav-pills li .show > a.nav-link,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer, #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer, #dashboard-content .DashboardComponentWrapper .Layout.Soil .ComponentBoxContainerBody .ComponentBoxContainerItem, .leaflet-bar a, div.tabular-data-container .ui-table.ui-widget, .ui-table-scrollable-body, .ui-widget-header,
    .tabular-data-container .paginator, div.tabular-data-container .ui-table-caption.ui-widget-header .select-menu .ui-multiselect, div.tabular-data-container .ui-table-caption.ui-widget-header .ui-helper-wrapper .ui-helper-clearfix button,
    .ui-multiselect .ui-multiselect-item:not(.ui-state-highlight):hover, #countdown, #countdown #countdown-controls #countdownTimerBtn, #countdown #countdown-controls #countdownRefreshBtn, #countdown #countdown-controls #countdownPausePlayBtn, #sliderPausePlayBtn, div.tabular-data-container .ui-table-caption.ui-widget-header .select-menu .ui-multiselect .ui-multiselect-trigger, .selectWrapper angular2-multiselect .cuppa-dropdown .selected-list .c-btn {
      background-color: $ContentBodyDark;
    }

    .station-marker .marker-value {
      background: #3a4246;
    }
    .station-marker .marker-value .icon-play {
      color: #3a4246;
    }

    .secondary-header {
      border-bottom: solid 1px transparent;
    }
    button.btn-long.view-dashboard {
      border-top: solid 1px transparent;
    }

    #date-picker-wrapper, .ui-widget-content, #filter-btn {
      border: solid 1px transparent;
    }

    #mapInfoPanel .station-variables-wrapper ul.nav-pills li {
      border-right: solid 1px $BorderDark;
    }

    a .rd-icon-button, {
      border: solid 1px rgba(255, 255, 255, 0.5);
    }

    #mapInfoPanel .station-variables-wrapper ul.nav-pills, #mapInfoPanel .station-variables-wrapper .tab-content .nearby-stations .row, .leaflet-bar a {
      border-bottom: solid 1px $BorderDark;
    }

    #header, .tabular-data-container .paginator .ui-widget-header, .tabular-data-container .paginator {
      border-top: solid 1px $BorderDark;
    }

    .outer-wrapper .container.top .content-page, .table-wrapper, .station-table .table td, .station-table .table th,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer, div.tabular-data-container .ui-table .ui-table-tbody > tr > td, div.tabular-data-container .ui-table .ui-table-thead > tr > th,
    div.tabular-data-container .ui-table.ui-widget .ui-table-scrollable-wrapper, div.tabular-data-container .ui-table-caption.ui-widget-header .select-menu .ui-multiselect,
    div.tabular-data-container .ui-table-caption.ui-widget-header .ui-helper-wrapper .ui-helper-clearfix button, #mainMap #date-picker-wrapper {
      border: solid 1px $BorderDark;
    }
    #header, .map-filter, .ui-widget-header {
      border-bottom: solid 1px $BorderDark;
    }

    .ui-paginator.ui-widget-header {
      border-bottom: none
    }




    div.tabular-data-container .tabular-data-buttons button.btn-primary {
      border: solid 1px rgba(255, 255, 255, 0.1);
    }



    .station-table .search-container .icon.icon-cancel-button-icon, .station-table .table tr:hover td, button.btn-long,
    .station-header .weather-data .btn-primary, .corporate-header-wrapper .SubNav .d-inline-block.show.dropdown .dropdown-menu.show button.dropdown-item a:hover, .dropdown-item:hover,
    #findStationListContainer .dropdown-menu .dropdown-item:hover, div.tabular-data-container .tabular-data-buttons button.btn-primary.active, div.tabular-data-container .ui-paginator .ui-state-active,
    .leaflet-popup-content-wrapper, .leaflet-popup-tip, div.tabular-data-container .ui-table-caption.ui-widget-header .ui-helper-wrapper .ui-helper-clearfix button:hover, #filter-btn,
    div.tabular-data-container.active .ui-table-caption.ui-widget-header .toolbar-wrapper .table-filter-btn, span.ShowValues {
      background-color: $BlueHighlight;
    }

    .map-filter a, .togglebutton label, .secondary-header a,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxHeader .MetricBoxTitle,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxFooter,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerHeader .ComponentBoxContainerTitle,
    #dashboard-content .DashboardComponentWrapper .Title,
    #dashboard-content .DashboardComponentWrapper .Time, #dashboard-content .DashboardComponentWrapper .Layout.DuelContainer .Feedback .ComponentBoxContainer p,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerBody .column-wrapper .Variable .date-wrapper,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxBody .MetricBoxBodyInner .MetricBoxSubText, .corporate-header-wrapper .SubNav .d-inline-block.show.dropdown .dropdown-menu.show button.dropdown-item a,
    .dropdown-item, .leaflet-popup-content-wrapper {
      color: $HeadingTextDark;
    }

    .map-filter #findStationListContainer #findStationList:focus, .map-filter #findStationListContainer .icon.icon-cancel-button-icon, .secondary-header a .icon-left-arrow-icon, .content-page h2, .content-page h3,
    .station-table .search-container #findStation, .station-table .table tr:hover td, #findStationListContainer .dropdown-menu .dropdown-item,
    #mapInfoPanel .station-variables-wrapper ul.nav-pills li a.nav-link, #mapInfoPanel .station-variables-wrapper .tab-content .nearby-stations .nearby-station-distance,
    #mapInfoPanel .station-variables-wrapper .tab-content .nearby-stations .nearby-station-name, #mapInfoPanel .station-variables-wrapper .tab-content .nearby-stations .nearby-station-name,
    .radar-summary-wrapper h2, .nearby-stations span.online::after, #mapInfoPanel .station-variables-wrapper .tab-content .nearby-stations .station-code,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxBody .MetricBoxBodyInner .MetricBoxValue,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxBody .MetricBoxBodyInner .MetricBoxValue .MetricBoxDesc,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxFooter .left span.Direction,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxFooter .left .alert,
    .station-header .station-info-wrapper,
    #dashboard-content .DashboardComponentWrapper .Variable, #dashboard-content .DashboardComponentWrapper .Layout.DuelContainer .AppStores .ComponentBoxContainer .ComponentBoxContainerBody .app-store-wrapper a,
    #dashboard-content .DashboardComponentWrapper .Layout.SeeAlso .ComponentBoxContainer .ComponentBoxContainerBody .ToolWrapper a,
    .map-filter #findStationListContainer #findStationList,
    #dashboard-content .DashboardComponentWrapper .Date,
    #dashboard-content .DashboardComponentWrapper .ComponentBoxContainer .ComponentBoxContainerBody .column-wrapper .Variable .estimated-rainfall,
    #dashboard-content-inside .MetricBoxWrapper .MetricBoxInnerWrapper .MetricBoxContainer .MetricBoxHeader .MetricBoxInfo .icon-info-icon,
    .corporate-header-wrapper .SubNav .d-inline-block.show.dropdown .dropdown-menu.show button.dropdown-item a:hover,
    .dropdown-item:hover, #findStationListContainer .dropdown-menu .dropdown-item:hover, .leaflet-bar a, #date-picker-wrapper input,
    div.tabular-data-container .ui-table .ui-table-thead > tr > th, .leaflet-popup-content, .ui-paginator .ui-paginator-prev, .ui-paginator .ui-paginator-last, .station-marker .marker-value,
    div.tabular-data-container .ui-table-caption.ui-widget-header .select-menu .ui-multiselect .ui-multiselect-item:not(.ui-state-highlight), .ui-multiselect .ui-multiselect-header .ui-multiselect-close,
    .station-header .station-info-wrapper .StationMetaWrapper .StationNameWrapper .ToolTip button .icon-info-icon, #dashboard-content .DashboardComponentWrapper .Layout.DuelContainer .Feedback .ComponentBoxContainer .icon.icon-speech-bubble-icon, #countdown #countdown-controls #countdownTimerBtn, #countdown #countdown-controls #countdownRefreshBtn, #countdown #countdown-controls #countdownPausePlayBtn, .content-page .settingsContainer .Col h4 {
      color: $White;
    }
    #map-legend div.ml-na, #map-legend, #map-legend .filter {
      color: $Black;
    }
    a .rd-icon-button {
      background-color: $Black;
    }

    #map-legend .filter {
      background: $White;
    }

    div.tabular-data-container .pi-step-forward:before {
      content: url(/assets/images/three-dots-white.svg);
      position: relative;
      top: -7px;
    }

    div.tabular-data-container .ui-paginator .pi-step-backward:before {
      content: url(/assets/images/three-dots-white.svg);
      position: relative;
      top: -7px;
    }


    #mainMap {
      background-color: #1e1e1e;
    }

  

  .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover, .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover, .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover, .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover {
    background-color: transparent;
  }

  }


}





