
.airtemperaturemaxlast24hrs,
.station-marker.airtemperaturemaxlast24hrs,
#map-legend.airtemperaturemaxlast24hrs div
{
  &.ml-g50 {
    background-color: #000000;
    color: $White;
  }
  &.ml-l50 {
    background-color: #3A0703;
    color: $White;
  }
  &.ml-l45 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l40 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l35 {
    background-color: #D5279D;
    color: $White;
  }
  &.ml-l30 {
    background-color: #F885CB;
    color: $White;
  }
  &.ml-l25 {
    background-color: #FB8D28;
  }
  &.ml-l20 {
    background-color: #FAB94D;
  }
  &.ml-l15 {
    background-color: #76BC6C;
  }
  &.ml-l10 {
    background-color: #FFF;
  }
  &.ml-l5 {
    background-color:  #FFF;
  }
  &.ml-l0 {
    background-color: #FFF;

  }
  &.ml-ln5 {
    background-color: #FFFF;

  }
  &.ml-ln10 {
    background-color: #FFF;

  }

  &.ml-na {
    background-color:#ccc ;
    border: solid 1px #ccc;
  }

}