//Filter text at the top of teh colour legend
.filter {
  text-align: center;
  background: $White;
}



.soiltemperature,
.station-marker.soiltemperature,
#map-legend.soiltemperature div
{
  &.ml-g50 {
    background-color: #000000;
    color: $White;
  }
  &.ml-l50 {
    background-color: #3A0703;
    color: $White;
  }
  &.ml-l45 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l40 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l35 {
    background-color: #D6289E;
    color: $White;
  }
  &.ml-l30 {
    background-color: #F885CB;
    color: $White;
  }
  &.ml-l25 {
    background-color: #FB8D28;
    color: $White;
  }
  &.ml-l20 {
    background-color: #FAB94D;
  }
  &.ml-l15 {
    background-color: #76BC6C;
  }
  &.ml-l10 {
    background-color: #95CF8B;
  }
  &.ml-l5 {
    background-color:  #B0DFA6;
  }
  &.ml-l0 {
    background-color: #D5E5FA;

  }

  &.ml-ln5 {
    background-color: #2873C0;
    color: $White;

  }
  &.ml-ln10 {
    background-color: #00395B;
    color: $White;
  }
  &.ml-na {
    background-color:#ccc ;
    border: solid 1px #ccc;
  }
}





/*
* EoShortCrop
*/
#map-legend.etotallcrop div,
.etoshortcrop,
.etotallcrop,
.station-marker.etoshortcrop,
#map-legend.etoshortcrop div {
  &.ml-g14 {
      background-color: #c6100e;
      color: $White;
    }
  &.ml-l14 {
    background-color: #f65102;
    color: $White;
  }
  &.ml-l12 {
    background-color: #ff8c00;
    color: $White;
  }
  &.ml-l10 {
    background-color: #ffc300;
  }
  &.ml-l8 {
    background-color: #b0e074;

  }
  &.ml-l6 {
    background-color: #45d9fa;
  }
  &.ml-l4 {
    background-color: #086ba0;
    color: $White;
  }
  &.ml-l2 {
    background-color: #003e62;
    color: $White;
  }
  &.ml-l1 {
    background-color: #001a3e;
    color: $White;
  }
  &.ml-na {
    background-color: #ccc !important;
    color: #000000;
  }

}


 // LastUpdated
.getlastupdatedminutes,  
.station-marker.getlastupdatedminutes,
#map-legend.getlastupdatedminutes div {
  &.ml-g720 {
    background-color: #6f0002;
    color: $White;
    z-index: 670 !important;
  }
  &.ml-l720 {
    background-color: #d20003;
    color: $White;
    z-index: 669 !important;
  }
  &.ml-l360 {
    background-color:#fa5c00;
    color: $White;
    z-index: 668 !important;
  }
  &.ml-l180 {
    background-color: #fe9700;
    color: $White;
    z-index: 667 !important;
  }
  &.ml-l60 {
    background-color: #fecb00;
    z-index: 666 !important;
  }
  &.ml-l30 {
    background-color: #a9d902;
    z-index: 665 !important;
  }
  &.ml-l15 {
    background-color: #12c002;
    color: $White;
  }





 }


/*
 * RelativeHumidity
 */
.relativehumidity,
.station-marker.relativehumidity,
#map-legend.relativehumidity div {

  &.ml-g100 {
    background-color:#00395B ;
    color: $White;

  }
  &.ml-l100 {
    background-color: #2873C0 ;
    color: $White;

  }
  &.ml-l90 {
    background-color: #82AEF1 ;

  }
  &.ml-l80 {
    background-color: #B1CFFC ;

  }
  &.ml-l70 {
    background-color: #D5E5FA;

  }
  &.ml-l60 {
    background-color: #FAB94D;
  }
  &.ml-l50 {
    background-color: #FB8D28 ;
    color: $White;
  }
  &.ml-l40 {
    background-color: #F885CB;
    color: $White;

  }
  &.ml-l30 {
    background-color:  #D6289E;
    color: $White;
  }
  &.ml-l20 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l10 {
    background-color: #860410 ;
    color: $White;
  }


  &.ml-na {
    background-color: #ccc;
  }
}


/*
 * wind_direction
 * wind_speed_kmh
 */
.wind_direction,
.station-marker.wind_direction
 {

  &.ml-g70 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #5e1902;
    }
  }
  &.ml-l70 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #c12403;
    }
  }
  &.ml-l60 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #e8411b;
    }
  }
  &.ml-l50 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #e57737;
    }
  }
  &.ml-l40 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #e7c908;
    }
  }
  &.ml-l30 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #c9df0c;
    }
  }
  &.ml-l20 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #81c736;
    }
  }
  &.ml-l15 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #5db873;
    }
  }
  &.ml-l10 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #7abab8;
    }
  }
  &.ml-l5 {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #9cbff0;
    }
  }
  &.ml-na {
    background-color:transparent;
    border: none;
    span.icon-direction-icon {
      color: #ccc;
    }
  }



}

.wind_speed_kmh,
.station-marker.wind_speed_kmh,
#map-legend.wind_direction div,
#map-legend.wind_speed_kmh div {

  &.ml-g70 {
    background-color:#5e1902;
    color: $White;
  }
  &.ml-l70 {
    background-color: #c12403;
    color: $White;
  }
  &.ml-l60 {
    background-color: #e8411b;
    color: $White;
  }
  &.ml-l50 {
    background-color:#e57737;
    color: $White
  }
  &.ml-l40 {
    background-color: #e7c908;
  }
  &.ml-l30 {
    background-color: #c9df0c;
  }
  &.ml-l20 {
    background-color: #81c736;
  }
  &.ml-l15 {
    background-color: #5db873;
  }
  &.ml-l10 {
    background-color: #7abab8;
  }
  &.ml-l5 {
    background-color: #9cbff0;
  }




  &.ml-na {
    background-color: #ccc;
  }
}







/*
 * SolarExposure
 */
 .solarexposure,
.station-marker.solarexposure,
#map-legend.solarexposure div {
   &.ml-g36 {
     background-color: #000000;
     color: $White;
   }
   &.ml-l36 {
     background-color: #3A0703;
     color: $White;
   }
   &.ml-l33 {
     background-color: #860410;
     color: $White;
   }
   &.ml-l30 {
     background-color: #C80E0E;
     color: $White;
   }
   &.ml-l27 {
     background-color: #D6289E;
     color: $White;
   }
   &.ml-l24 {
     background-color: #F885CB;
     color: $White;

   }
   &.ml-l21 {
     background-color: #FB8D28;
     color: $White;
   }
   &.ml-l18 {
     background-color: #FAB94D;
   }
   &.ml-l15 {
     background-color: #76BC6C;
   }
   &.ml-l12 {
     background-color:#95CF8B;
   }
   &.ml-l9 {
     background-color: #B0DFA6;
   }
   &.ml-l6 {
     background-color: #82AEF1;
   }
   &.ml-l3 {
    background-color: #D5E5FA;

  }
  &.ml-na {
    background-color: #ccc;
  }
}

/*
 * ErrorsToday
 * ErrorsLast7Days
 */

.errorstoday,
.errorslast7days,
.station-marker.errorstoday,
.station-marker.errorslast7days,
#map-legend.errorstoday div,
#map-legend.errorslast7days div {

  &.ml-g500 {
    background-color: #910002;
    color: $White;
  }
  &.ml-l500 {
    background-color: #e92002;
    color: $White;
  }
  &.ml-l20 {
    background-color: #fe9800;
    color: $White;
  }
  &.ml-l5 {
    background-color: #e9d900;
  }
  &.ml-l1 {
    background-color: #42d904;
    color: $White;
  }

}

/* CSS For toggling markers visibility */
#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.getlastupdatedminutes {
        &.ml-l15 , &.ml-l30 , &.ml-l60 , &.ml-l180 , &.ml-l360 , &.ml-l720, &.ml-g720 {
         display: none;
       }
    }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-l15 {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-l30 {
        display: flex;
      }
  }
  &.ml-l60 {
      div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-l60 {
        display: flex;
      }
  }
  &.ml-l360 {
      div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-l360 {
        display: flex;
      }
  }
  &.ml-l720 {
      div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-l720 {
        display: flex;
      }
  }
  &.ml-g720 {
       div.leaflet-marker-icon.station-marker.getlastupdatedminutes.ml-g720 {
        display: flex;
      }
  }
  
}

// errorstoday
// errorslast7days
#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.errorstoday, div.leaflet-marker-icon.station-marker.errorslast7days {
        &.ml-l1 , &.ml-l5 , &.ml-l20 , &.ml-l500 , &.ml-g500 {
         display: none;
       }
    }
  }
  &.ml-l1 {
      div.leaflet-marker-icon.station-marker.errorstoday.ml-l1, div.leaflet-marker-icon.station-marker.errorslast7days.ml-l1 {
        display: flex;
      }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.errorstoday.ml-l5, div.leaflet-marker-icon.station-marker.errorslast7days.ml-l5{
        display: flex;
      }
  }
  &.ml-l20 {
      div.leaflet-marker-icon.station-marker.errorstoday.ml-l20, div.leaflet-marker-icon.station-marker.errorslast7days.ml-l20 {
        display: flex;
      }
  }
  &.ml-l500 {
      div.leaflet-marker-icon.station-marker.errorstoday.ml-l500, div.leaflet-marker-icon.station-marker.errorslast7days.ml-l500 {
        display: flex;
      }
  }
  &.ml-g500 {
       div.leaflet-marker-icon.station-marker.errorstoday.ml-g500, div.leaflet-marker-icon.station-marker.errorslast7days.ml-g500  {
        display: flex;
      }
  }
}





// soiltemperature

#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.soiltemperature,       
    div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs
    {
        &.ml-ln10 , &.ml-ln5 , &.ml-l0 , &.ml-l5 , &.ml-l10, &.ml-l15 , &.ml-l20 , &.ml-l25 , &.ml-l30 , &.ml-l35, &.ml-l40 , &.ml-l45 ,&.ml-l50 ,
        &.ml-g50 , &.ml-na  {
         display: none;
       }
    }
  }
  &.ml-ln10 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-ln10,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-ln10
      {
        display: flex;
      }
  }
  &.ml-ln5 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-ln5,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-ln5
      {
        display: flex;
      }
  }
  &.ml-l0 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l0,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l0
       {
        display: flex;
      }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l5,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l10,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l15,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l20 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l20,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l20
      {
        display: flex;
      }
  }
  &.ml-l25 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l25,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l30,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l30
      {
        display: flex;
      }
  }
  &.ml-l35 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l35,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l35
      {
        display: flex;
      }
  }
  &.ml-l40 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l40,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l40
      {
        display: flex;
      }
  }
  &.ml-l45 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-l45,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-l45
      {
        display: flex;
      }
  }
  &.ml-g45 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-g45,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-g45
      {
        display: flex;
      }
  } 
   &.ml-g50 {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-g50,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-g50
      {
        display: flex;
      }
  } 
  &.ml-na {
      div.leaflet-marker-icon.station-marker.soiltemperature.ml-na,
      div.leaflet-marker-icon.station-marker.airtemperaturemaxlast24hrs.ml-na
      {
        display: flex;
      }
  } 
}


// relativehumidity
#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.relativehumidity
    {
        &.ml-l10 , &.ml-l20 , &.ml-l30 , &.ml-l40 , &.ml-l50, &.ml-l60 , &.ml-l70 , &.ml-l80 , &.ml-l90 , &.ml-l100,
        &.ml-g100, &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l20 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l20
      {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l30
      {
        display: flex;
      }
  }
  &.ml-l40 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l40
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l60 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l60
      {
        display: flex;
      }
  }
  &.ml-l70 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l70
      {
        display: flex;
      }
  }
  &.ml-l80 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l80
      {
        display: flex;
      }
  }
  &.ml-l90 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l90
      {
        display: flex;
      }
  }
  &.ml-l100 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-l100
      {
        display: flex;
      }
  }
  &.ml-g100 {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-g100
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.relativehumidity.ml-na
      {
        display: flex;
      }
  }
}

// wind_direction
// wind_speed_kmh

#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.wind_direction,
    div.leaflet-marker-icon.station-marker.wind_speed_kmh
    {
        &.ml-l5 , &.ml-l10 , &.ml-l15, &.ml-l20, &.ml-l30 , &.ml-l40, &.ml-l50, &.ml-l60 , &.ml-l70, &.ml-g70, &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l5,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l10,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l15,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l20 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l20,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l20
      {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l30,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l30
      {
        display: flex;
      }
  }
  &.ml-l40 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l40,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l40
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l50,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l60 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l60,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l60
      {
        display: flex;
      }
  }
  &.ml-l70 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-l70,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-l70
      {
        display: flex;
      }
  }
  &.ml-g70 {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-g70,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-g70
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.wind_direction.ml-na,
      div.leaflet-marker-icon.station-marker.wind_speed_kmh.ml-na
      {
        display: flex;
      }
  }
}

//solar .solarexposure#map-wrapper { 
  #map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.solarexposure
    {
        &.ml-l3 , &.ml-l6, &.ml-l9 , &.ml-l12 , &.ml-l15 , &.ml-l18 , &.ml-l21, &.ml-l24 , &.ml-l27 , &.ml-l30,
        &.ml-l33 , &.ml-l36 , &.ml-na  {
         display: none;
       }
    }
  }
  &.ml-l3 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l3
      {
        display: flex;
      }
  }
  &.ml-l6 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l6
      {
        display: flex;
      }
  }
  &.ml-l9 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l9
      {
        display: flex;
      }
  }
  &.ml-l12 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l12
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l18 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l18
      {
        display: flex;
      }
  }
  &.ml-l21 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l21
      {
        display: flex;
      }
  }
  &.ml-l24 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l24
      {
        display: flex;
      }
  }
  &.ml-l27 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l27
      {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l30
      {
        display: flex;
      }
  }
  &.ml-l33 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l33
      {
        display: flex;
      }
  }
  &.ml-l36 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l36
      {
        display: flex;
      }
  }
  &.ml-l36 {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-l36
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.solarexposure.ml-na
      {
        display: flex;
      }
  }
}


//etotallcrop
//etoshortcrop

#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.etotallcrop,
    div.leaflet-marker-icon.station-marker.etoshortcrop
    {
        &.ml-l1 , &.ml-l2 , &.ml-l4 , &.ml-l6 , &.ml-l8, &.ml-l10 , &.ml-l12 , &.ml-l14 , &.ml-g14 , &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l1 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l1,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l1
      {
        display: flex;
      }
  }
  &.ml-l2 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l2,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l2
      {
        display: flex;
      }
  }
  &.ml-l4 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l4,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l4
      {
        display: flex;
      }
  }
  &.ml-l6 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l6,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l6
      {
        display: flex;
      }
  }
  &.ml-l8 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l8,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l8
      {
        display: flex;
      }
  }
  &.ml-l0 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l10,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l12 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l12,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l12
      {
        display: flex;
      }
  }
  &.ml-l14 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-l14,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-l14
      {
        display: flex;
      }
  }
   &.ml-g14 {
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-g14,
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-g14
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.etoshortcrop.ml-na,
      div.leaflet-marker-icon.station-marker.etotallcrop.ml-na
      {
        display: flex;
      }
  }
}

// offline markers force them to be grey
.station-marker.dbca.offline-marker, .station-marker.dpird.offline-marker {
  background-color: #cccccc !important;
  border: solid 1px #cccccc !important;
}


// using legend filter removes BoM stations whilst they have no values
#map-wrapper {
  &.highlight {
    .station-marker.bom {
      display:none;
    }
  }
}

// Markers that have a class of offline-marker will be shown differently
div.leaflet-marker-icon.station-marker.offline-marker {
  background-color: #C8C8C8 !important;
  div.marker-value {
    display: none;
  }
  div.marker-value-direction {
    display: none;
  }
}