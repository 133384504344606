.airtemperature,
.station-marker.airtemperature,
#map-legend.airtemperature div
{
  &.ml-g50 {
    background-color: #000000;
    color: $White;
  }
  &.ml-l50 {
    background-color: #3A0703;
    color: $White;
  }
  &.ml-l45 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l40 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l35 {
    background-color: #D6289E;
    color: $White;
  }
  &.ml-l30 {
    background-color: #F885CB;
    color: $White;
  }
  &.ml-l25 {
    background-color: #FB8D28;
    color: $White;
  }
  &.ml-l20 {
    background-color: #FAB94D;
  }
  &.ml-l15 {
    background-color: #76BC6C;
  }
  &.ml-l10 {
    background-color: #95CF8B;
  }
  &.ml-l5 {
    background-color:  #B0DFA6;
  }
  &.ml-l0 {
    background-color: #D5E7FC;

  }
  &.ml-ln5 {
    background-color: #2873C0;
    color: $White;

  }
  &.ml-ln10 {
    background-color: #00395B;
    color: $White;
  }
  &.ml-na {
    background-color:#ccc ;
    border: solid 1px #ccc;
  }
}


#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.airtemperature
    {
        &.ml-ln10 , &.ml-ln5 , &.ml-l0 , &.ml-l5 , &.ml-l10, &.ml-l15 , &.ml-l20 , &.ml-l25 , &.ml-l30 , &.ml-l35, &.ml-l40 , &.ml-l45 ,&.ml-l50 ,
        &.ml-g50 , &.ml-na  {
         display: none;
       }
    }
  }
  &.ml-ln10 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-ln10
      {
        display: flex;
      }
  }
  &.ml-ln5 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-ln5
      {
        display: flex;
      }
  }
  &.ml-l0 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l0
      {
        display: flex;
      }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l20 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l20
      {
        display: flex;
      }
  }
  &.ml-l25 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l30 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l30
      {
        display: flex;
      }
  }
  &.ml-l35 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l35
      {
        display: flex;
      }
  }
  &.ml-l40 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l40
      {
        display: flex;
      }
  }
  &.ml-l45 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l45
      {
        display: flex;
      }
  }
  &.ml-g45 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-l50
      {
        display: flex;
      }
  } 
   &.ml-g50 {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-g50
      {
        display: flex;
      }
  } 
  &.ml-na {
      div.leaflet-marker-icon.station-marker.airtemperature.ml-na
      {
        display: flex;
      }
  } 
}