#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate
    {
      &.ml-l1, &.ml-l5 , &.ml-l10 , &.ml-l15 , &.ml-l25, &.ml-l50 , &.ml-l100 , &.ml-l150 , &.ml-l200 , &.ml-l300, &.ml-l400 , &.ml-g400 , &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l1 {
    div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l1
    {
      display: flex;
    }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l25 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l100 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l100
      {
        display: flex;
      }
  }
  &.ml-l150 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l150
      {
        display: flex;
      }
  }
  &.ml-l200 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l200
      {
        display: flex;
      }
  }
  &.ml-l300 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l300
      {
        display: flex;
      }
  }
  &.ml-l400 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-l400
      {
        display: flex;
      }
  }
  &.ml-g400 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-g400
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.rainfallmetricsmonthtodate.ml-na
      {
        display: flex;
      }
  }
}

.rainfallmetricsmonthtodate,
.station-marker.rainfallmetricsmonthtodate,
#map-legend.rainfallmetricsmonthtodate div {

  &.ml-g400 {
    background-color: #000000;
    color: $White;
  }
  &.ml-l400 {
    background-color: #3A0703;
    color: $White;
  }
  &.ml-l300 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l200 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l150 {
    background-color: #D6289E;
    color: $White;
  }
  &.ml-l100 {
    background-color: #F885CB;
    color: $White;
  }
  &.ml-l50 {
    background-color: #FB8D28;
    color: $White;
  }
  &.ml-l25 {
    background-color: #FAB94D;

  }
  &.ml-l15 {
    background-color: #76BC6C;

  }
  &.ml-l10 {
    background-color: #95CF8B;

  }
  &.ml-l5 {
    background-color: #B1CFFC;
  }

  &.ml-l1 {
    background-color: #D5E5FA;
  }

  &.ml-na {
    background-color: #CCCCCC;
    &.ml-lowavailability {
      background-color: #a3a3a3;
      border-color: #a3a3a3;
    }
  }
}