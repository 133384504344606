
// rainfallmetricsyeartodate
#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate
    {
        &.ml-l25 , &.ml-l50 , &.ml-l100 , &.ml-l200 , &.ml-l300, &.ml-l400 , &.ml-l500, &.ml-l750  , &.ml-l1000 , &.ml-l1500 , &.ml-l2000, &.ml-g2000 , &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l25 {
    div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l100 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l100
      {
        display: flex;
      }
  }
  &.ml-l200 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l200
      {
        display: flex;
      }
  }
  &.ml-l300 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l300
      {
        display: flex;
      }
  }
  &.ml-l400 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l400
      {
        display: flex;
      }
  }
  &.ml-l500 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l500
      {
        display: flex;
      }
  }
  &.ml-l750 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l750
      {
        display: flex;
      }
  }
  &.ml-l1000 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l1000
      {
        display: flex;
      }
  }
  &.ml-l1500 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l1500
      {
        display: flex;
      }
  }
  &.ml-l2000 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-l2000
      {
        display: flex;
      }
  }
  &.ml-g2000 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-g2000
      {
        display: flex;
      }
  }
  &.ml-na {
      div.leaflet-marker-icon.station-marker.rainfallmetricsyeartodate.ml-na
      {
        display: flex;
      }
  }
}

/*
 * rainfallmetricsyeartodate #392
 */
 .rainfallmetricsyeartodate,
 .station-marker.rainfallmetricsyeartodate,
 #map-legend.rainfallmetricsyeartodate div {
 
   &.ml-g2000 {
     background-color: #000000;
     color: $White;
   }

   &.ml-l2000 {
     background-color: #3A0703;
     color: $White;
   }
   &.ml-l1500 {
     background-color: #860410;
     color: $White;
   }

   &.ml-l1000 {
     background-color: #C80E0E;
     color: $White;
   }
   &.ml-l750 {
     background-color: #D6289E;
     color: $White;
   }
   &.ml-l500 {
     background-color: #F885CB;
     color: $White;
   }
   &.ml-l400 {
     background-color: #FB8D28;
     color: $White;
   }
   &.ml-l300 {
     background-color: #FAB94D ;
   }
   &.ml-l200 {
     background-color: #76BC6C;

   }
   &.ml-l100 {
     background-color: #95CF8B;

   }
   &.ml-l50 {
     background-color: #B1CFFC ;
   }
   &.ml-l25 {
     background-color: #D5E5FA;
   }
   &.ml-na {
     background-color: #ccc;
     &.ml-lowavailability {
      background-color: #a3a3a3;
      border-color: #a3a3a3;
    }
   }
 }














