@charset "utf-8";

@import "sass/variables";
@import "sass/markerMetricColours";
@import "sass/marker_metrics/rainfall_last7days";
@import "sass/marker_metrics/rainfall_monthtodate";
@import "sass/marker_metrics/rainfall_since9am";
@import "sass/marker_metrics/rainfall_to9am";
@import "sass/marker_metrics/rainfall_rainfall_period";
@import "sass/marker_metrics/rainfall_yeartodate";
@import "sass/marker_metrics/airtemperature";
@import "sass/marker_metrics/airtemperature_min_last24";
@import "sass/marker_metrics/airtemperature_max_last24";


@import "sass/highChartsDarkTheme";
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";

body {
  font-family: 'Roboto', sans-serif!important;

}

.mat-tooltip {
  //width: 200px;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  border: solid 1px black;
  padding: 10px;
  top: 0;
  left: 50px;
  font-size: 12px;
  text-align: left;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
}



div.tabular-data-container #date-picker-wrapper .btn-wrapper {
  display: none!important;
}



#main-container.app-no-header {

  .search-container {
    top: 12px;
    z-index:99;
    &.active {
    position: absolute;
    top: 12px;
    z-index: 10000;
    text-align: center;
    width: 100%;
    height: 100%;
    }
  }

}


#main-container.map-page.map-fullscreen {
  .search-container {
    display: none;
  }

}


.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $buttonOn;
  border-color:  $buttonOn;
}

.popover-body {
  -webkit-box-shadow: 4px 6px 1px 0 rgba(0,0,0,0.3) ;
  box-shadow: 4px 6px 1px 0 rgba(0,0,0,0.3) ;
}

.selected-item {
  background: #f2f2f2;
}

.leaflet-tooltip {
  position: absolute;
  width: 200px;
  white-space: unset;

}

button {
  cursor: pointer;
}


.ui-table .ui-sortable-column .ui-sortable-column-icon, .ui-table .ui-sortable-column .ui-sortable-column-icon:hover {
  font-size: 0.75em;
}


.map-fullscreen #map-wrapper .radar-time.menu-open {
  z-index: 99999!important;
}


.outer-wrapper .container.top .content-page p {
  margin-bottom: 5px;
}


#header {
  app-rd-icon-button[name="10 day summary"] {
    display: none;
  }
}

#main-container.map-page #header {
  app-rd-icon-button[name="10 day summary"] {
    display: block;
  }
  .button-wrapper {
    width: 150px;
  }
}


@media (max-width: 850px) {
  #main-container.map-page #header {
    .button-wrapper {
      width: 45px;
      right: 0;
    }
  }
}





//welcome screen

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body.modal-open {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-backdrop.show {
    opacity: .85;
  }

  .modal-content {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    .StartScreen {
      position: relative;
      .close:not(:disabled):not(.disabled):hover {
        opacity: 1;
      }
      button.close {
        position: absolute;
        display: block;
        top: -25px;
        right: -25px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        color: $text;
        text-shadow: none;
        border: solid 1px $lightGreyBorder;
        font-size: 18px;
        background: $White;
        opacity: 1;
        z-index: 9;
        span.icon {
          position: relative;
          top: 2px;
        }

      }
      .modal-body {
        display: flex;
        padding: 0;
        .LeftCol {
          flex: 1;
          min-height: 450px;
          border-bottom-left-radius: 10px;
          text-align: center;
          position: relative;
          .Title {
            margin: 15px 15px 10px 15px;
            font-size: 18px;
            font-weight: 700;
          }
          .Hero {
            width: 100%;
            margin-bottom: 15px;
            img {
              max-width: 250px;
              height: auto;
              display: block;
              margin: auto;
              -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(13, 63, 136, 0.4);
              box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(13, 63, 136, 0.4);
            }
          }
          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
            margin: 0 50px;
          }
          button {
            margin-top: 10px;
            background: $BlueHighlight;
            color: $White;
            border: none;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 300;
            height: 30px;
            cursor: pointer;
             a {
               color: $white;
             }
          }
          .ToggleInfo {
            position: absolute;
            left: 15px;
            bottom: 35px;
            font-size: 12px;
            font-weight: 300;
          }
        }
        .RightCol {
          flex: 0 0 265px;
          background: $BlueHighlight;
          border-bottom-right-radius: 10px;
          .VideoContainer {
          }
          .hidden-video {
            width: 50px;
            height: auto;
            visibility: hidden;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
          }
          .VideoContainer {
            text-align: center;
            margin: 52px auto 0 auto;
            width: 220px;
            position: relative;
            cursor: pointer;
            .icon-play-icon {
              color: $White;
              background: rgba(0, 0, 0, 0.8);
              -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
              box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
              position: absolute;
              z-index: 2;
              left: 50%;
              top: 50%;
              width: 40px;
              height: 40px;
              padding: 0;
              line-height: 40px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              font-size: 40px;
              margin-left: -20px;
              margin-top: -20px;
            }
            img {
              max-width: 220px;
              display: block;
            }
          }

          .Title {
            color: $White;
            font-weight: 700;
            margin: 10px 20px 0;
          }
          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 300;
            margin: 0 20px 20px 20px;
            color: $White;

            a {
              font-weight: 700;
              text-decoration: none;
              color: $White;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .BrowserInfo {
        position: absolute;
        bottom: 0;
        background: #4e4e4e;
        color: $White;
        width: 100%;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        .BrowserInfoInside {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          height: 35px;
          p {
            font-weight: 300;
            text-align: center;
            font-size: 12px;
            padding: 0;
            margin: 0;
            a {
              font-weight: 700;
              color: $BlueHighlight;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  body.modal-open {
    #filter-btn {
      z-index: 999;
    }
  }
  body.modal-open .modal-content .StartScreen.show-video .modal-body .RightCol {
    margin-bottom: 0;
  }

}


@media (max-width: 850px) {
  body.modal-open .modal-content .StartScreen .modal-body {
    flex-direction: column;
  }
  body.modal-open .modal-content .StartScreen .modal-body .LeftCol .ToggleInfo {
    position: static;
    margin: 15px 15px 10px 15px;

  }
  body.modal-open .modal-content .StartScreen .modal-body .LeftCol {
    min-height: 390px;
  }
  body.modal-open .modal-content .StartScreen .modal-body .RightCol {
    .Title {
      margin-top: 15px;
    }
    text-align: center;
  }
  body.modal-open .modal-content .StartScreen .modal-body .RightCol {
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  body.modal-open .modal-content .StartScreen .BrowserInfo .BrowserInfoInside {
    height: 55px;
    padding: 0 15px;
  }
  body.modal-open .modal-content .StartScreen .modal-body .RightCol {
    margin-bottom: 45px;
  }
}

@media (max-width: 400px) {
  body.modal-open .modal-content .StartScreen .modal-body .LeftCol .Hero img {
    max-width: 100%;
  }

  body.modal-open .modal-content .StartScreen .modal-body .LeftCol .Hero {
    padding-left: 10px;
    padding-right: 10px;
  }
  body.modal-open .modal-content .StartScreen .modal-body .LeftCol p {
    margin: 0 10px;
  }
  body.modal-open .modal-content .StartScreen .BrowserInfo .BrowserInfoInside p {
    line-height: 1.2em;
  }


}






button:focus, .btn-outline-primary:focus {
  outline: 1px dotted;
  box-shadow: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 35px;
}

.tooltip-inner {
  max-width: 350px;
  padding: 15px;
  color: $White;
  text-align: left;
  background-color: $Value;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

app-station-dashboard {
  .new-window {
    display: block;
  }
}

#main-wrapper {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
}

//hack for targeted browsers
#main-container.ie.version_11-0, #main-container.firefox.version_40-0 {
  .beacon {
    margin-left: -9px;
    margin-top: -9px;
  }
}

#main-container {
  .maximize {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 30px;
    height: 20px;
    text-align: center;
    color: $White;
    background: $BlueHighlight;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: -21px;
    z-index: 2;
    a {
      display: block;
    }
  }

  &.map-page.map-fullscreen, &.-page.map-fullscreen {
    top: 49px;
    height: calc(100% - 49px);
    #map-wrapper {
      top: 0;
      height: 100%;
    }

    .corporate-header-wrapper {
      display: none;
    }
    #header {
      .view_title {
        display: none;
      }
    }
    #map-content {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .maximize {
      top: -2px;
      transform: rotate(180deg);
    }
    #dashboard-content {
      height: calc(100% - 154px);
    }

    #mapInfoPanel {
      top: 0;
      right: -200%;
      height: 100%;
      &.menu-open {
        top: 0;
        right: 0;
        height: 100%;
      }
    }
    .map-filter {
      position: fixed;
      top: 0;
    }
    #header {
      display: none;
    }
    .leaflet-pseudo-fullscreen {
      top: 0 !important;
      height: 100% !important;
    }

    .region-controls {
      z-index: 999999;
    }
    #map-content {
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    &.map-page.map-fullscreen, &.-page.map-fullscreen {
      top: 0;
      height: 100%;
      #mapInfoPanel {
        right: -200%;
      }
      #mapInfoPanel.menu-open {
        right: 0;
        height: 100%;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
    }
  }

  &.app-no-header {
    .corporate-header-wrapper {
      display: none;
    }
    #map-content {
      height: 100%;
    }
    #header .view_title {
      display: none;
    }
    .maximize {
      transform: rotate(180deg);
      top: 47px;

    }

    #dashboard-content {
      height: calc(100% - 150px);
    }
    .outer-wrapper {
      top: 50px;
      height: calc(100% - 50px);
    }

    #mapInfoPanel {
      top: 50px;
      //right: -200%;
      height: calc(100% - 50px);
      &.menu-open {
        right: 0;
      }

    }

    @media (max-width: 991px) {
      #station-content #dashboard-content {
        height: calc(100% - 120px);
      }
      #mapInfoPanel {
        top: 0;
        right: -200%;
        height: 100%;
        &.menu-open {
          right: 0;
        }
      }
    }

  }

}

@media (max-width: 991px) {
  #main-container.map-fullscreen {
    #mapInfoPanel {
      width: 100% !important;
      background: #ffffff;
      -webkit-box-shadow: -5px 0 5px -1px rgba(0, 0, 0, 0.3);
      box-shadow: -5px 0 5px -1px rgba(0, 0, 0, 0.3);
      padding: 0 15px;
      position: absolute;
      z-index: 9999;
      top: -200%;
      right: initial;
      height: 100%;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;
    }
  }
}

//MOVED HERE AS WONT WORK OTHERWISE ?
.station-marker {
  border: solid 1px rgba(0, 111, 255, 0.5);
  width: 15px !important;
  height: 15px !important;
  text-align: center;
  border-radius: 50%;
  background-color: #002c5a;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  &.dbca {
    background-color: $dbca;
    border: solid 1px #247900;
  }
  &.bom {
    background-color: $white;
    border: solid 1px #ababab;
    z-index: -999 !important; // want the markers behind
  }
  &.wcorp {
    background-color: $wcorp;
    border: solid 1px $wcorp;
    z-index: -998 !important; // want the markers behind
  }
  /**&.offline-marker {
    background-color: red !important;
    border: solid 1px red;
  }**/
  .marker-value {
    background: $white;
    padding: 3px 5px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    top: 21px;
    font-size: 11px;
    font-weight: normal;
    color: $Value;
    .icon-play {
      position: absolute;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      top: -17px;
      left: 50%;
      margin-left: -9px;
      font-size: 20px;
      color: white;
    }
  }

}

.station-marker {
  &.wind_direction {
    text-align: center;
    .marker-value-direction {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-direction-icon {
        font-size: 24px;
        display: block;
        color: $darkBlueBtn;
        position: relative;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.6);
      }
      .marker-value {
        position: absolute;
        white-space: nowrap;
      }
    }
  }

}

.dbca.wind_direction .marker-value .icon-wind-arrow {
  color: $dbca;

}

.MetricBoxFooter.N .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(180deg);
}

.MetricBoxFooter.NNE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(202.5deg);
}

.MetricBoxFooter.NE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(225deg);
}

.MetricBoxFooter.E .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(247.5deg);
}

.MetricBoxFooter.ENE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(247.5deg);
}

.MetricBoxFooter.E .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(270deg);
}

.MetricBoxFooter.ESE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(292.5deg);
}

.MetricBoxFooter.SE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(315deg);
}

.MetricBoxFooter.SSE .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(337.5deg);
}

.MetricBoxFooter.S .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(360deg);
}

.MetricBoxFooter.SSW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(22.5deg);
}

.MetricBoxFooter.SW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(45deg);
}

.MetricBoxFooter.WSW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(67.5deg);
}

.MetricBoxFooter.W .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(90deg);
}

.MetricBoxFooter.WNW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(112.5deg);
}

.MetricBoxFooter.NW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(135deg);
}

.MetricBoxFooter.NNW .left .icon-direction-icon {
  transition: all .3s ease-in-out;
  transform: rotate(157.5deg);
}

//Wind direction map

.station-marker.wind_direction {
  &.dir-N {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(180deg);
    }
  }
  &.dir-NNE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(202.5deg);
    }
  }
  &.dir-NE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(225deg);
    }
  }
  &.dir-E {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(270deg);
    }
  }
  &.dir-ENE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(247.5deg);
    }
  }
  &.dir-ESE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(292.5deg);
    }
  }
  &.dir-SE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(315deg);
    }
  }
  &.dir-SSE {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(337.5deg);
    }
  }
  &.dir-S {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(360deg);
    }
  }
  &.dir-SSW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(22.5deg);
    }
  }
  &.dir-SW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(45deg);
    }
  }
  &.dir-WSW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(67.5deg);
    }
  }
  &.dir-W {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(90deg);
    }
  }
  &.dir-WNW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(112.5deg);
    }
  }
  &.dir-NW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(135deg);
    }
  }
  &.dir-NNW {
    .icon-direction-icon {
      transition: all .3s ease-in-out;
      transform: rotate(157.5deg);
    }
  }

}

.leaflet-marker-icon {
  &.marker-cluster {
    border: solid 1px rgba(0, 111, 255, 0.5);
    width: 50px !important;
    height: 50px !important;
    border-radius: 25px;
    background-color: $darkBlueBtn;
    color: white;
    text-align: center;
    font-weight: bold;
    padding-top: 15px;
    &.dbca {
      background-color: $dbca;
      border: solid 1px #247900;
    }
    &.wcorp {
      background-color: $wcorp;
      border: solid 1px $wcorp;
    }
    &.bom {
      color: $black;
      background-color: $white;
      border: solid 1px #ababab;
    }
  }
}

.beacon {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $orange;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index:9999;
}

.beacon:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0;
  top: 0;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 2px 2px $orange;
  -webkit-animation: active 2s infinite linear;
  animation: beacon-animation 2s infinite linear;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px;
}

@-webkit-keyframes beacon-animation {
  0% {
    -webkit-transform: scale(.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes beacon-animation {
  0% {
    transform: scale(.1);
    opacity: 1;
  }

  70% {
    transform: scale(2.5);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}

.icon-station-alert {
  position: relative;
  top: -9px;
}

span.online {
  color: $green;
  font-size: 16px;
}

span.online::after {
  content: "Online";
  position: relative;
  font-size: 12px;
  left: 3px;
  top: -4px;
  color: $text;
  font-weight: 300;
}

button.btn-long {
  width: 100%;
  background: $darkBlueBtn;
  border: solid 1px $lightBlueBtnBorder;
  padding: 10px 0 10px 0;
  display: block;
  text-align: center;
  border-radius: 50px;
  color: $white;
  &:hover {
    background: $buttonOn;
    text-decoration: none;
  }
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: $text;
  cursor: pointer;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #dcbe64;
}

button.drop-down-button {
  padding: 0 10px;
  margin-top: 0;
  background: $darkBlueBtn;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: $white;
  font-weight: 300;
  height: 30px;
  font-size: 14px;
  &:hover {
    background: $buttonOn;
    color: $white;
  }
  &:focus {
    box-shadow: none;
  }
}

.dropdown-toggle::after {
  margin-left: 15px;
  vertical-align: 1px;
}

.station-table {
  .all-stations-wrapper, .favourite-stations-wrapper {
    padding: 0;
    background: $white;
    border-radius: 10px;
    position: relative;
    top: 0;
    clear: both;
    .toolbar-wrapper {
      position: absolute;
      padding: 15px 30px;
      color: $white;
      background: $defaultBlue;
      border: none;
      top: 0;
      height: 50px;
      width: 100%;
      h2 {
        text-align: left;
        color: $white;
        margin-top: 0;
        display: inline-block;
        float: left;
        font-size: 16px;
      }
      .inside-tools-wrapper {
        float: right;
        button {
          margin-top: 0;
          width: 160px;
          min-width: 160px;
          background: #0d3f88;
          border: none;
          border-radius: 10px;
          color: #ffffff;
          font-weight: 300;
          height: 48px;
          margin-left: 10px;
          float: left;
          &:hover, &.active {
            background: $buttonOn;
          }

        }
      }
    }
  }
  .favourite-stations-wrapper {
    margin-bottom: 90px;
  }

}

.outer-wrapper {
  position: absolute;
  top: 150px;
  left: 0;
  background: $BodyBG;
  width: 100%;
  height: calc(100% - 150px);
  overflow: auto;
 -webkit-overflow-scrolling: touch;
  .container.top {
    margin-top: 50px;
    padding: 0 150px;
    .content-page {
      background: $white;
      border: solid 1px $lightGreyBorder;
      width: 100%;
      padding: 35px 50px 20px 50px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      margin-bottom: 30px;
      h2 {
        margin-top: 0;
        font-size: 2rem;
      }
      p, ul li {
        font-weight: 300;
      }
      a {
        color: $buttonOn;
        border-bottom: dotted 1px $buttonOn;
        font-weight: 500;
        &:hover {
          color: $darkBlueBtn;
          border-bottom: dotted 1px $darkBlueBtn;
          text-decoration: none;
        }
      }

    }
  }
}

@media (max-width: 991px) {
  .outer-wrapper {
    .container.top {
      padding: 0 15px;
    }
  }
}

@media (max-width: 700px) {
  .outer-wrapper .container.top {
    margin-top: 20px;
    .content-page {
      margin-bottom: 80px;

    }
  }

}

@media (max-width: 575px) {
  .outer-wrapper .container.top > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .outer-wrapper .container.top .content-page {
    padding: 0 15px 15px 15px;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 25px;
    }
    p:last-child {
      margin-bottom: 10px;
    }
  }

}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.leaflet-popup-close-button {
  display: none;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 999999 !important;
}



body.dark-gotham {
  .owl-dt-container {
    background-color: $ContentBodyDark;
  }
  .owl-dialog-container {
    color: rgba(255, 255, 255,.87);
  }

  .owl-dt-container-info .owl-dt-container-info-active {
    color: $buttonOn;
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border: none;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(3, 43, 104, 0.6);
    box-shadow: 0 5px 20px 0 rgba(197, 197, 197, 0.2), 0 13px 24px -11px rgba(78, 99, 132, 0.6);
  }


  .owl-dt-control-button {
  color: $white;
  }
  .owl-dt-calendar-table .owl-dt-calendar-header {
    color: rgba(255, 255, 255,.4);
  }
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
    content: '';
    position: absolute;
    top: 0;
    left: -.5em;
    right: -.5em;
    height: 1px;
    background: rgba(255, 255, 255, .12);
  }
  .owl-dt-calendar-table .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    cursor: pointer;
    color: rgba(255, 255, 255,.85);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  svg {
    fill: $buttonOn;
  }
  .owl-dt-timer-content .owl-dt-timer-input {
    background: $BodyBGDark;
    color: $white;
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    color: rgba(255, 255, 255, 0.85);
    background-color: $buttonOn;
  }
  .owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    color: rgba(255, 255, 255, .4);
  }

  .owl-dt-container-info .owl-dt-container-range:last-child {
    border-top: 1px solid rgba(255, 255, 255, .12);
  }
  .owl-dt-container-row {
    border-bottom: 1px solid rgba(255, 255, 255, .12);
  }

}





// custom styles Creative-Tim plugin
.cuppa-dropdown {

  .selected-list {
    max-width: calc(100% - 50px);
    .c-angle-up, .c-angle-down {
     width: 12px;
      height: 12px;
      right: 20px;
      top: 41%;;
      position: absolute;
    }
    .c-btn {
      border: solid 1px #e3e3e3;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      color: #333333;
      font-weight: 300;
      padding: 7px 18px 8px!important;

      &:focus {
        outline: none!important;
      }
    }
  }
  .dropdown-list {
    .arrow-2 {
      border-bottom: 15px solid $white;
    }
    ul li:hover  {
      background: #f5f5f5;
    }

    .list-area {
      max-width: 100%;
      border: none!important;
      border-radius: .125rem!important;
      box-shadow: 0 10px 50px 0 rgba(0,0,0,.2)!important;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      label {
        position: relative;
        padding-left: 2em;
        vertical-align: middle;
        user-select: none;
        cursor: pointer;
        margin: 0;
        color: $black;
        font-weight: 300;
        font-size: 14px;
        width: 100%;
      }
    }
  }

}



@media (max-width: 991px) {

  .content-page .settingsContainer {
    flex-direction: column;
  }

  .cuppa-dropdown .selected-list {
    max-width: 100%!important;
    width: 100%;
  }
  .cuppa-dropdown .dropdown-list .list-area {
    max-width: 100%!important;
    width: 100%;
  }

  .content-page .settingsContainer .Col {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }



}


@media (max-width: 600px) {
  .popover {
    width: 100%;
    max-width: 100%;

  }
}



.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: initial !important;
  flex: none !important;
}

.p-datatable-wrapper {
  overflow: auto !important;
}

#dashboard-content-inside {
  .station-outage {
    display: flex;
    justify-content: center;
    .alert.alert-danger {
      text-align: center;
      margin: 0 30px;
    }
  }
  max-width: 1600px;
  margin: auto;
  padding-top: 20px;
  .MetricBoxWrapper {
    display: block;
    width: calc(100% + 64px);
    overflow-x: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 0 15px 0;
    left: 0;
    right: 0;
    .MetricBoxInnerWrapper {
      width: 1600px;
      display: flex;
      justify-content: space-between;
      //MetricBox styles
      .MetricBoxContainer {
        display: flex;
        flex: 0 0 250px;
        flex-direction: column;
        height: 182px;
        background: $White;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        .MetricBoxHeader {
          padding: 7px 10px 0 10px;
          flex: 0 0 30px;
          display: flex;
          justify-content: space-between;
          position: relative;
          .MetricBoxTitle {
            text-transform: uppercase;
            font-size: 14px;
            padding-top: 6px;
            font-weight: 500;
            color: $HeadingText;
          }
          .MetricBoxInfo {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            position: absolute;
            right: 0;
            height: 35px;
            .icon-info-icon {
              color: $BluePrimary;
              font-size: 30px;
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
        .MetricBoxBody {
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 110px;
          flex-direction: column;
          .MetricBoxBodyInner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .MetricBoxValue {
              display: flex;
              justify-content: space-around;
              font-size: 30px;
              font-weight: bold;
              color: $Value;
              .MetricBoxDesc {
                display: flex;
                flex-direction: column;
                margin-left: 3px;
                line-height: 12px;
                font-size: 14px;
                color: $Value;
                height: 24px;
                margin-top: 10px;
                .top {
                  flex: 1;
                  white-space: nowrap;
                }
                .bottom {
                  flex: 1;
                  white-space: nowrap;
                }
              }
            }
            .MetricBoxSubText {
              font-size: 14px;
              margin-top: -12px;
              font-weight: 300;

            }
          }
        }
        .MetricBoxFooter {
          display: flex;
          flex: 1;
          height: 140px;
          padding: 0 10px 0 10px;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;
          color: $LightText;
          .right {
            text-align: right;
          }
          .left {
            text-align: left;
            position: relative;
            .icon-direction-icon {
              font-size: 25px;
              color: $BlueHighlight;
              display: inline-block;
            }
            span.Direction {
              position: relative;
              top: -7px;
              left: 3px;
              color: $Value;
              font-weight: 700;
            }
            .alert {
              border-radius: initial;
              border-bottom-left-radius: initial;
              border-bottom-right-radius: initial;
              position: initial;
              width: initial;
              text-align: initial;
              font-size: initial;
              line-height: initial;
              padding: initial;
              margin: initial;
              background: transparent;
              color: $LightText;
              font-weight: 300;
              &.green span {
                background: $Green;
              }
              &.orange span {
                background: $Orange;
              }
              &.red span {
                background: $Red;
              }
              span {
                width: 7px;
                height: 16px;
                border-radius: 7px;
                display: inline-block;
                position: relative;
                top: 2px;
                margin-right: 5px;
              }

            }
          }
        }
      }
    }
  }
}

.custom-dropdown{
  position: absolute;
  padding: 24px;
  pointer-events: auto;
  right: 10px;
  top: 30px;
}
.custom-dropdown{
  box-shadow: rgb(136, 136, 136) 3px 3px 10px;
  border: 1px solid rgb(153, 153, 153);
  background: rgb(255, 255, 255);
  padding: 5px 0px;
}
.custom-dropdown-item {
    cursor: pointer;
    padding: 0.5em 1em;
    color: rgb(51, 51, 51);
    background: none;
    font-size: 11px;
    transition: background 250ms, color 250ms;
}
.custom-dropdown-item:hover {
  background-color: rgb(51, 92, 173);
  color:white;
}

.leftside-icon {
  color: #15263a;
  font-size: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}