// rainfallmetricsperiod,

#map-wrapper { 
  &.highlight {
    div.leaflet-marker-icon.station-marker.rainfallmetricsperiod
    {
        &.ml-l5 , &.ml-l10 , &.ml-l15 , &.ml-l25, &.ml-l50 , &.ml-l100 , &.ml-l200 , &.ml-l300, &.ml-l400, &.ml-l500 , &.ml-l750 &.ml-g750 , &.ml-na {
         display: none;
       }
    }
  }
  &.ml-l5 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l5
      {
        display: flex;
      }
  }
  &.ml-l10 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l10
      {
        display: flex;
      }
  }
  &.ml-l15 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l15
      {
        display: flex;
      }
  }
  &.ml-l25 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l25
      {
        display: flex;
      }
  }
  &.ml-l50 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l50
      {
        display: flex;
      }
  }
  &.ml-l100 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l100
      {
        display: flex;
      }
  }
  &.ml-l200 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l200
      {
        display: flex;
      }
  }
  &.ml-l300 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l300
      {
        display: flex;
      }
  }
  &.ml-l400 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l400
      {
        display: flex;
      }
  }
  &.ml-l500 {
    div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l500
    {
      display: flex;
    }
}
&.ml-l750 {
  div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-l750
  {
    display: flex;
  }
}
&.ml-g750 {
      div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-g750
      {
        display: flex;
      }
 }
  &.ml-na {
    div.leaflet-marker-icon.station-marker.rainfallmetricsperiod.ml-na
    {
      display: flex;
    }
  }
}


/*
 * RainFallMetrics.MonthToDate
 * RainfallMetrics.period 
*/ 


.rainfallmetricsperiod,
.station-marker.rainfallmetricsperiod,
#map-legend.rainfallmetricsperiod div {

  &.ml-g750 {
    background-color:#000000;
    color: $White;
  }
  &.ml-l750 {
    background-color: #3A0703;
    color: $White;
  }
  &.ml-l500 {
    background-color: #860410;
    color: $White;
  }
  &.ml-l400 {
    background-color: #C80E0E;
    color: $White;
  }
  &.ml-l300 {
    background-color: #D6289E;
    color: $White;
  }
  &.ml-l200 {
    background-color: #F885CB;
    color: $White;
  }
  &.ml-l100 {
    background-color: #FB8D28;
    color: $White;
  }
  &.ml-l50 {
    background-color: #FAB94D ;


  }
  &.ml-l25 {
    background-color: #76BC6C ;
  }
  &.ml-l15 {
    background-color: #95CF8B;
  }
  &.ml-l10 {
    background-color: #B1CFFC;

  }

  &.ml-l5 {
     background-color: #D5E5FA;
  }
  &.ml-na {
    background-color: #ccc;
    &.ml-lowavailability {
      background-color: #a3a3a3;
      border-color: #a3a3a3;
    }
  }
}


